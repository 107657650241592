import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const ROUTE = {
    ADD(req, token) {
        const url = `${CONSTANT.URL}route`;
        return API_REQUEST("POST", url, req, {
            "Content-Type": "multipart/form-data",
        });
    },
    GET_ALL(token) {
        const url = `${CONSTANT.URL}route`;
        return API_REQUEST("GET", url, { Authorization: `Bearer ${token}` });
    },
    GET_BY_ID(id) {
        const url = `${CONSTANT.URL}route/${id}`;
        return API_REQUEST("GET", url);
    },
    GET_BY_MENU(menu) {
        const url = `${CONSTANT.URL}route/menu/${menu}`;
        return API_REQUEST("GET", url);
    },
    UPDATE(req, id, token) {
        console.log("id", id);
        const url = `${CONSTANT.URL}route/${id}`;
        return API_REQUEST("PUT", url, req, {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        });
    },
    DELETE(req, id, token) {
        console.log("id", id);
        const url = `${CONSTANT.URL}route/delete/${id}`;
        return API_REQUEST("PUT", url, req, {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        });
    },

}

