import React from "react";
import { HOME } from "../../../assets";
import splitStringUsingRegex from "../../../utils/splitStringUsingRegex";
import { motion, Variants } from "framer-motion";

export default function Banner2() {
  const text =
    "Nam rutrum semper turpis ac iaculis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec vel dolor ut risus lobortis fringilla. Vivamus bibendum augue lacus,nec pharetra risus dignissim eu.";
  const textChars = splitStringUsingRegex(text);

  const charVariants = {
    hidden: { opacity: 0, y: 20 }, // Adjust animation properties as needed
    reveal: { opacity: 1, y: 0 },
  };
  return (
    <div className="w-full h-auto">
      <div className="w-full grid md:grid-cols-2">
        <div className="md:col-span-1 w-full">
          <div className="w-auto lg:h-[770px] md:h-[580px] h-[400px]">
            <img
              src={HOME.BANNER_IMG2}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="md:col-span-1 w-full bg-[#F6F9F8] flex md:justify-start md:pl-10 items-center justify-center">
          <div className="w-[85%] flex flex-col max-w-screen-2xl md:mr-[5%] md:py-0 py-15">
            <div className="xl:text-5xl lg:text-4xl md:text-2xl text-xl font-felixTitling">
              <p className="xl:mb-3 lg:mb-1">PROIN A ERAT ID</p>
              <p className="xl:mb-3 lg:mb-1">QUAMCSODALS POR</p>
              <p> NEC VITAE ORACI</p>
            </div>
            <motion.p
              initial="hidden"
              whileInView="reveal"
              transition={{ staggerChildren: 0.02 }}
              className="xl:text-[20px] lg:text-[16px] md:text-[15px] font-poppins mt-4 font-extralight"
            >
              {textChars.map((char, index) => (
                <motion.span
                  key={index}
                  variants={charVariants}
                  transition={{ duration: 0.5 }}
                >
                  {char}
                </motion.span>
              ))}
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
}
