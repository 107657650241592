import React from "react";
import fb from "../../assets/facebook-app-symbol.svg";
import tw from "../../assets/twitter.png";
import insta from "../../assets/insta.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {HOME} from "../../assets";

export default function Footer() {
  const fadeInAnimationVarience = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeIn",
      },
    },
  };
  return (
    <motion.div
      variants={fadeInAnimationVarience}
      initial="initial"
      whileInView="animate"
      className="w-full h-full flex flex-col gap-2 items-center text-center justify-center pt-18 pb-10 lg:py-[80px]"
    >
      <div className="mt-3 mb-7 flex flex-col text-2xl font-felixTitling lg:flex-row gap-5">
        <Link to="/home"><p>HOME</p></Link>
        {/* <Link to="/amenities"><p>AMENITIES</p></Link> */}
        <Link to="/accommodation"><p>ACCOMMODATION</p></Link>
        <Link to="/facilities"><p>FACILITIES</p></Link>
        <Link to="/ayurvedha"><p>AYURVEDHA</p></Link>
        <Link to="/contact"><p>CONTACT US</p></Link> 

      </div>
      <div className="w-[50%] lg:w-[25%] h-auto">
        <img src={HOME.R_LOGO} className="w-full h-auto object-cover"/>
      </div>
      {/* <p className="text-xl font-felixTitling">STAY IN TOUCH</p> */}
      {/* <p className="text-md font-poppins text-[#0D1316] font-extralight">
        Sign up to receive news letters from Rustic
      </p> */}
      {/* <div className="w-full px-[5%] lg:px-[20%]">
        <form className="flex flex-row border border-[#00A1ED] w-[100%] ">
          <input
            type="email"
            placeholder="Fill in your email address"
            className="w-[70%] pl-5 outline-none lg:w-[80%] p-3 font-poppins font-light text-lg"
          />
          <button className="bg-[#00A1ED] text-[#fff] w-[30%] p-5 lg:w-[20%] font-extralight">
            SUBSCRIBE
          </button>
        </form>
      </div> */}
      <div className="m-10 flex flex-row gap-10">
        <a href="https://www.instagram.com/rustic_leisures?igsh=bzd2aThmOHljbjRt">
        <img src={insta} className="w-[35px] h-[35px]" /></a>
        <img src={fb} className="w-[35px] h-[35px]" />
        <img src={tw} className="w-[35px] h-[35px]" />
      </div>
      <div className="flex flex-col md:flex-row mb-7 gap-[10px] lg:gap-2 text-lg font-extralight text-[#0D1316] font-poppins">
      <Link to="/underconstruction"><p className="md:pb-0 pb-2">Privacy Policy</p></Link>
        <span className="hidden md:inline">|</span>
        <Link to="/terms"><p className="md:pb-0 pb-2">General Terms and Conditions</p></Link>
        {/* <span className="hidden md:inline">|</span> */}
        {/* <p className="md:pb-0 pb-2">Blog</p> */}
        {/* <span className="hidden md:inline">|</span> */}
        {/* <Link to="/contact"><p>Contact Us</p></Link> */}
      </div>
    </motion.div>
  );
}
