import React from "react";
import { Route, Routes } from "react-router-dom";
import NoPage from "../pages/noPage/NoPage";
import HomeScreen from "../pages/unAuth/home/HomeScreen";
import Menu from "../pages/menu/Menu";
import Menu2 from "../pages/menu/Menu";
import BasicLayout from "./BasicLayout";
import SecondaryLayout from "./SecondaryLayout";
import ComingSoon from "../pages/comingsoon/ComingSoon";
import Terms from "../pages/terms/Terms";
import Accommodation from "../pages/accommodation/Accommodation";
import Activities from "../pages/activities/Activities";
import Ayurvedha from "../pages/ayurvedha/Ayurvedha";
import Facilities from "../pages/facilities/Facilities";
import Amenities from "../pages/amenities/Amenities";
import Contact from "../pages/contact/Contact";
import Gallery from "../pages/gallery/Gallery";
import Ayurvedhalist from "../pages/ayurvedha/Ayurvedhalist";
import AdminLogin from "../pages/adminLogin/AdminLogin";
import DynamicMenu from "../pages/menu/DynamicMenu";

export default function UnAuthLayout() {
  return (
    <>
      <Routes>
        <Route path="/">
          <Route
            index
            element={
              <BasicLayout>
                <HomeScreen />
              </BasicLayout>
            }
          />
          <Route path="admin-login" element={<AdminLogin />} />
          <Route
            path="home"
            element={
              <BasicLayout>
                <HomeScreen />
              </BasicLayout>
            }
          />
          <Route path="menu/:menu" element={<DynamicMenu />} />

          {/* <Route
            path="/menu"
            element={
              <SecondaryLayout>
                <Menu />
              </SecondaryLayout>
            }
          />
          <Route
            path="/menu2"
            element={
              <SecondaryLayout>
                <Menu2 />
              </SecondaryLayout>
            }
          /> */}
          <Route
            path="/facilities"
            element={
              <BasicLayout>
                <Facilities />
              </BasicLayout>
            }
          />
          <Route
            path="/gallery"
            element={
              <BasicLayout>
                <Gallery />
              </BasicLayout>
            }
          />
          <Route
            path="/contact"
            element={
              <BasicLayout>
                <Contact />
              </BasicLayout>
            }
          />
          <Route
            path="/amenities"
            element={
              <BasicLayout>
                <Amenities />
              </BasicLayout>
            }
          />
          <Route
            path="*"
            element={
              <BasicLayout>
                <NoPage />
              </BasicLayout>
            }
          />
          <Route
            path="ayurvedha"
            element={
              <BasicLayout>
                <Ayurvedha />

              </BasicLayout>
            }
          />
          <Route
            path="ayurvedhalist"
            element={
              <BasicLayout>
                <Ayurvedhalist />
              </BasicLayout>
            }
          />
          <Route
            path="/terms"
            element={
              <BasicLayout>
                <Terms />
              </BasicLayout>
            }
          />
          <Route
            path="/accommodation"
            element={
              <BasicLayout>
                <Accommodation />
              </BasicLayout>
            }
          />
          <Route
            path="/activities"
            element={
              <BasicLayout>
                <Activities />
              </BasicLayout>
            }
          />
          <Route
            path="/underconstruction"
            element={
              <BasicLayout>
                <ComingSoon />
              </BasicLayout>
            }
          />
        </Route>
      </Routes>
    </>
  );
}
