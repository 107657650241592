import React, { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/header/Header";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import EmpList from "../pages/admin/employee/EmpList";
import EmpAdd from "../pages/admin/employee/EmpAdd";
import EmpUpdate from "../pages/admin/employee/EmpUpdate";
import NoPage from "../pages/noPage/NoPage";
import AdminSideBar from "../components/sidebar/AdminSideBar";
import EmpView from "../pages/admin/employee/EmpView";
import ProfileScreen from "../pages/admin/profile/ProfileScreen";

import { AuthContext } from "../utils/AuthContext";
import NotAllowed from "../pages/noPage/NotAllowed";
import HeaderAdmin from "../components/header/HeaderAdmin";
import ListRoute from "../pages/admin/route/ListRoute";
import CreateRoute from "../pages/admin/route/CreateRoute";
import EditRoute from "../pages/admin/route/EditRoute";

import Gallery from "../pages/admin/gallery/Gallery";
import ContactList from "../pages/admin/contact/ContactList";
import ContactView from "../pages/admin/contact/ContactView";

// import RidesAdd from "../pages/admin/rides/RidesAdd";
// import Ridelist from "../pages/admin/rides/Ridelist";
// import RideUpdate from "../pages/admin/rides/RideUpdate";
// import RideView from "../pages/admin/rides/RideView";


export default function AdminLayout() {
  const { user } = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div suppressHydrationWarning={true}>
      <div className="dark:bg-boxdark-2 dark:text-bodydark">
        <div className="flex h-screen overflow-hidden">
          <AdminSideBar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <HeaderAdmin sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div>
              <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                <Routes>
                  <Route path="/">
                    <Route index element={<Dashboard />} />
                    <Route path="dashboard" element={<Dashboard />} />

                    <Route path="route">
                      <Route
                        index
                        element={user.role === 0 ? <ListRoute /> : <NotAllowed />}
                      />
                      <Route
                        path="add"
                        element={user.role === 0 ? <CreateRoute /> : <NotAllowed />}
                      />
                      <Route
                        path="update/:id"
                        element={user.role === 0 ? <EditRoute /> : <NotAllowed />}
                      />
                    </Route>
                    <Route
                      path="gallery"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <Gallery />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />


                    {/* <Route
                      path="payment"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <PaymentList />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />
                    <Route
                      path="block-chalets"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <BlockChelet />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    /> */}



                    {/* <Route path="/rideAdd" element={<RidesAdd />} />
                    <Route path="/rideList" element={<Ridelist />} />
                    <Route path="update/:id" element={<RideUpdate />} />
                    <Route path="view/:id" element={<RideView />} /> */}




                    <Route path="profile" element={<ProfileScreen />} />
                    {/* <Route path="events">
                      <Route index element={<EventList />} />
                      <Route path="add" element={<EventAdd />} />
                      <Route path="view/:id" element={<EventView />} />
                      <Route path="update/:id" element={<EventUpdate />} />
                    </Route> */}

                    <Route path="contact">
                      <Route
                        index
                        element={user.role === 0 || user.role === 1 ? (<ContactList />) : (<NotAllowed />)}
                      />
                      <Route
                        path="view/:id"
                        element={user.role === 0 || user.role === 1 ? (<ContactView />) : (<NotAllowed />)}
                      />
                    </Route >


                    <Route path="admin">
                      <Route
                        index
                        element={user.role === 0 ? <EmpList /> : <NotAllowed />}
                      />
                      <Route
                        path="add"
                        element={user.role === 0 ? <EmpAdd /> : <NotAllowed />}
                      />
                      <Route
                        path="update/:id"
                        element={
                          user.role === 0 ? <EmpUpdate /> : <NotAllowed />
                        }
                      />
                      <Route
                        path="view/:id"
                        element={user.role === 0 ? <EmpView /> : <NotAllowed />}
                      />
                    </Route>

                    <Route path="*" element={<NoPage />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
