import React, { useEffect } from 'react'
import { IMG } from '../../assets';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { motion, useAnimation } from "framer-motion";

export default function Accommodation() {
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const array = [{
    id: 1,
    title1: "LUXURY",
    title2: "SUITE",
    des1: "The pinnacle of",
    des2: "elegance and comfort",
    singletxt: "Single [Per Day]",
    singleinr: "INR ",
    singlepay: "12,640",
    doubletxt: "Double [Per Day]",
    doublepay: "17,888",
    txt1: "Meal Plan",
    txt2: "AP",

  },
  {
    id: 2,
    title1: "PREMIUM",
    title2: "ROOMS",
    des1: "The pinnacle of",
    des2: "elegance and comfort",
    singletxt: "Single [Per Day]",
    singleinr: "INR ",
    singlepay: "12,640",
    doubletxt: "Double [Per Day]",
    doublepay: "17,888",
    txt1: "Meal Plan",
    txt2: "AP",

  },
  {
    id: 3,
    title1: "DELUXE",
    title2: "ROOMS",
    des1: "The pinnacle of",
    des2: "elegance and comfort",
    singletxt: "Single [Per Day]",
    singleinr: "INR ",
    singlepay: "12,640",
    doubletxt: "Double [Per Day]",
    doublepay: "17,888",
    txt1: "Meal Plan",
    txt2: "AP",

  },
  {
    id: 4,
    title1: "EXECUTIVE",
    title2: "ROOMS",
    des1: "The pinnacle of",
    des2: "elegance and comfort",
    singletxt: "Single [Per Day]",
    singleinr: "INR ",
    singlepay: "12,640",
    doubletxt: "Double [Per Day]",
    doublepay: "17,888",
    txt1: "Meal Plan",
    txt2: "AP",

  }
  ]

  const array2 = [
    {
      id: 1,
      title1: "ABHYANGA",
      title2: "(OIL MASSGE)",
      dur: "Duration",
      min: "60 Minutes",
      price: "Price",
      txt: "Double [Per Day]",
      singleinr: "INR ",
      pay: "17,888",
    },
    {
      id: 2,
      title1: "NAVARAKKIZHI",
      title2: "(RICE MASSAGE)",
      dur: "Duration",
      min: "60 Minutes",
      price: "Price",
      txt: "Double [Per Day]",
      singleinr: "INR ",
      pay: "17,888",
    },
    {
      id: 3,
      title1: "PODIKKIZHI",
      title2: "(BUNDLE MASSAGE)",
      dur: "Duration",
      min: "60 Minutes",
      price: "Price",
      txt: "Double [Per Day]",
      singleinr: "INR ",
      pay: "17,888",
    },
    {
      id: 4,
      title1: "SIRODHARA",
      title2: "(MEDICAL HEAD BATH)",
      dur: "Duration",
      min: "60 Minutes",
      price: "Price",
      txt: "Double [Per Day]",
      singleinr: "INR ",
      pay: "17,888",
    },
    {
      id: 5,
      title1: "NAVARAKKIZHI",
      title2: "(RICE MASSAGE)",
      dur: "Duration",
      min: "60 Minutes",
      price: "Price",
      txt: "Double [Per Day]",
      singleinr: "INR ",
      pay: "17,888",
    },
    {
      id: 6,
      title1: "NAVARAKKIZHI",
      title2: "(RICE MASSAGE)",
      dur: "Duration",
      min: "60 Minutes",
      price: "Price",
      txt: "Double [Per Day]",
      singleinr: "INR ",
      pay: "17,888",
    },
  ]

  return (
    <div className="w-full h-auto flex flex-col pb-10">
      {/* <div className="w-full bg-green h-auto "> */}
      <div className='h-auto w-auto relative'>
        <div className='md:h-screen h-[555px]'>
          <img
            src={IMG.ACCOM}
            alt="images"
            style={{ width: "100%" }}
            className=" object-cover md:h-screen h-[555px]"
          />
        </div>
        <div className="absolute bottom-[25%] pb-0 xl:pb-0  text-white w-full px-5 lg:px-20 text-center flex flex-col gap-5 md:gap-20 ">
          <motion.p
            initial="hidden"
            whileInView="reveal"
            transition={{ staggerChildren: 0.02 }}
            className="font-Dalmatins leading-[70px] lg:leading-[90px] text-xl  lg:text-4xl">
            <motion.span
              transition={{ duration: 0.8 }}
            >
              Packages
            </motion.span>
          </motion.p>
        </div>
      </div>
      <div className=' bg-white w-auto h-auto flex justify-center items-center md:pt-18 md:pb-8 pt-12 pb-6'>
        <div className='xl:w-[50%] lg:w-[60%] md:w-[70%] px-1 md:px-0'>
          <p className='font-poppins xl:text-[25px] 2xl:text-[25px] text-[15px] lg:text-[22px] md:text-[19px] text-center font-extralight'>We offer packages tailored to meet your every need. Choose from our exclusive room packages across four categories: Luxury Suites,
            Premium Rooms, Deluxe Rooms, and Executive Rooms. Indulge in personalized Ayurveda Spa treatments with bespoke wellness packages. For events and celebrations,
            our banquet halls and spaces come with customized packages, ensuring a flawless experience.
            Whether it's accommodation, rejuvenation, or events, we have the perfect package for you.</p>
        </div>
      </div>
      <div className='bg- w-full h-full '>
        <div className='w-auto h-auto flex justify-center items-center p-15 bg-r'>
          <div className='w-auto '>
            <p className='2xl:text-[59px] lg:text-[50px] md:text-[46px] text-[30px] font-felixTitling font-light text-center'>ACCOMMODATION</p>
          </div>
        </div>
        <div className='bg-re w-full h-full flex justify-center items-center bg-gren xl:pb-0 pb-35'>
          <div className='h-auto  w-[80%] bg-blu gap-[2%] grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1'>
            {array.map((data) => (
              <div className='bg-[#F1F9FF] relative w-auto  pb-8'>
                <div className=' w-full absolute top-[-30px] flex justify-center items-center '>
                  <svg id="bedroom" xmlns="http://www.w3.org/2000/svg" width="55.884" height="55.884" viewBox="0 0 50.884 50.884" className='bg-white border-2 border-[#BCE0FD] h-[70px] w-[70px] p-4 rounded-full'>
                    <g id="Group_69" data-name="Group 69" transform="translate(0)">
                      <path id="Path_1415" data-name="Path 1415" d="M47.918,175.056a2.969,2.969,0,0,0-2.966,2.966v5.115H15.4a5.776,5.776,0,0,0-5.73-5.116H5.932v-7.056a2.966,2.966,0,0,0-5.932,0v29a.652.652,0,0,0,.652.652H5.28a.652.652,0,0,0,.652-.652v-5.3H34.213a.652.652,0,0,0,0-1.3H5.28a.652.652,0,0,0-.652.652v5.3H1.3V170.966a1.661,1.661,0,0,1,3.323,0v17.182a.652.652,0,0,0,.652.652H45.6a.652.652,0,0,0,.652-.652V178.022a1.661,1.661,0,0,1,3.323,0v21.291H46.257v-5.3a.652.652,0,0,0-.652-.652H37.728a.652.652,0,1,0,0,1.3h7.224v5.3a.652.652,0,0,0,.652.652h4.628a.652.652,0,0,0,.652-.652V178.022A2.969,2.969,0,0,0,47.918,175.056Zm-38.251,4.27a4.47,4.47,0,0,1,4.415,3.811H5.932v-3.811ZM5.932,187.5v-3.054h39.02V187.5Z" transform="translate(0 -149.734)" fill="#195fab" />
                      <path id="Path_1416" data-name="Path 1416" d="M89.056,31.368h2.5a.652.652,0,0,0,0-1.3h-2.5a.652.652,0,0,0,0,1.3Z" transform="translate(-78.792 -26.794)" fill="#195fab" />
                      <path id="Path_1417" data-name="Path 1417" d="M365.233,31.368h2.5a.652.652,0,1,0,0-1.3h-2.5a.652.652,0,0,0,0,1.3Z" transform="translate(-324.941 -26.794)" fill="#195fab" />
                      <path id="Path_1418" data-name="Path 1418" d="M58.716,15.222H92.029a3.919,3.919,0,0,0,3.914-3.914V.652A.652.652,0,0,0,95.291,0H55.454A.652.652,0,0,0,54.8.652V11.308A3.919,3.919,0,0,0,58.716,15.222Zm30.418-1.3H61.612V9.324A1.524,1.524,0,0,1,63.134,7.8H87.612a1.524,1.524,0,0,1,1.522,1.522v4.593ZM56.107,1.3H94.639v10a2.612,2.612,0,0,1-2.609,2.609H90.439V9.324A2.83,2.83,0,0,0,87.612,6.5H63.134a2.83,2.83,0,0,0-2.827,2.827v4.593H58.716a2.612,2.612,0,0,1-2.609-2.609Z" transform="translate(-48.844 0)" fill="#195fab" />
                    </g>
                    <g id="Group_70" data-name="Group 70" transform="translate(35.418 10.207)">
                      <path id="Path_1419" data-name="Path 1419" d="M327.387,95.183H326.4a.652.652,0,1,1,0-1.3h.985a.652.652,0,0,1,0,1.3Z" transform="translate(-325.749 -93.878)" fill="#195fab" />
                    </g>
                    <g id="Group_71" data-name="Group 71" transform="translate(15.351 10.207)">
                      <path id="Path_1420" data-name="Path 1420" d="M158.863,95.183H141.841a.652.652,0,1,1,0-1.3h17.022a.652.652,0,0,1,0,1.3Z" transform="translate(-141.189 -93.878)" fill="#195fab" />
                    </g>
                    <path id="Path_1421" data-name="Path 1421" d="M228.076,168.334a.658.658,0,0,1-.481-1.093,2.518,2.518,0,0,0,.143-2.561,4.042,4.042,0,0,1,.706-4.19.652.652,0,0,1,.946.9,2.742,2.742,0,0,0-.437,2.815,3.753,3.753,0,0,1-.4,3.919A.651.651,0,0,1,228.076,168.334Z" transform="translate(-202.701 -142.861)" fill="#195fab" />
                    <path id="Path_1422" data-name="Path 1422" d="M160.936,168.334a.658.658,0,0,1-.481-1.093,2.518,2.518,0,0,0,.143-2.561,4.042,4.042,0,0,1,.706-4.19.652.652,0,0,1,.946.9,2.742,2.742,0,0,0-.437,2.815,3.754,3.754,0,0,1-.4,3.919A.651.651,0,0,1,160.936,168.334Z" transform="translate(-142.861 -142.861)" fill="#195fab" />
                    <path id="Path_1423" data-name="Path 1423" d="M295.217,168.334a.658.658,0,0,1-.481-1.093,2.518,2.518,0,0,0,.143-2.561,4.041,4.041,0,0,1,.706-4.19.652.652,0,0,1,.946.9,2.742,2.742,0,0,0-.437,2.815,3.753,3.753,0,0,1-.4,3.919A.651.651,0,0,1,295.217,168.334Z" transform="translate(-262.542 -142.861)" fill="#195fab" />
                  </svg>

                </div>
                <div className=' w-full h-auto flex justify-center items-center pt-15 pb-6'>
                  <p className='font-felixTitling text-[29px] text-center'>{data.title1} <br></br>{data.title2}</p>
                </div>
                <div className=' pb-5'>
                  <p className='text-center font-poppins text-[18px] font-extralight'>{data.des1} <br></br> {data.des2}</p>
                </div>
                <div className='px-[25%]'>
                  <hr className='w-full text-[#BCE0FD] ' />
                </div>
                <div className=' h-auto py-5'>
                  <p className='font-poppins text-[22px] text-center'>{data.singletxt}<br></br><span className='font-bold text-[25px] font-poppins'>{data.singleinr}</span> <span className='text-[#A98445] text-[25px] font-extrabold font-poppins'>{data.singlepay}</span> </p>
                </div>
                <div className='px-[25%]'>
                  <hr className='w-full text-[#BCE0FD] ' />
                </div>
                <div className=' h-auto py-5'>
                  <p className='font-poppins text-[22px] text-center'>{data.doubletxt}<br></br><span className='font-bold text-[25px] font-poppins'>{data.singleinr}</span> <span className='text-[#A98445] text-[25px] font-extrabold font-poppins'>{data.doublepay}</span> </p>
                </div>
                <div className='px-[25%]'>
                  <hr className='w-full text-[#BCE0FD] ' />
                </div>
                <div className=' py-5'>
                  <p className='text-center font-poppins text-[20px]'>{data.txt1}<br></br> <span className='font-semibold text-[26px] font-poppins'>{data.txt2}</span></p>
                </div>
                <div className=' w-full h-auto flex justify-center items-center p-3'>
                  <button className='bg-[#A98445] p-2 w-[50%] rounded-md text-[20px] font-felixTitling font-light text-white'>Book Now</button>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>

    </div>
  )
}
