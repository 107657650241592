import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CONSTANT } from "../../constant/Constant";
import { ROUTE } from "../../api/route";

export default function DynamicMenu() {
  const { menu } = useParams();
  const [menuData, setMenuData] = useState(null);
  const getEmpList = async (menu) => {
    try {
      const res = await ROUTE.GET_BY_MENU(menu);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      console.log("res", res)
      setMenuData(res.data[0]);
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  console.log("menuData", menuData);
  useEffect(() => {
    getEmpList(menu);
  }, [menu]);
  return (
    <div>
      {menuData !== null ? (
        <img
          src={`${CONSTANT.BASE_URL}menu/${menuData.menu}`}
          alt="menu"
          className="w-full"
        />
      ) : (
        <p>Loading</p>
      )}
    </div>
  );
}
